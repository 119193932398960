import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ImageDialog from "./ImageDialog";
import { products } from "../util/cardlists";
import { randd } from "../util/cardlists";
import { labs } from "../util/cardlists";
import { robotics } from "../util/cardlists";
import { prototyping } from "../util/cardlists";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`,
	};
}

export default function ServicesTabs() {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const productsImages = products.map((img) => (
		<ImageDialog key={img.imag} props={img} />
	));

	const productRandD = randd.map((img) => (
		<ImageDialog key={img.imag} props={img} />
	));
	const aiLabs = labs.map((img) => <ImageDialog key={img.imag} props={img} />);
	const roboticsTabs = robotics.map((img) => (
		<ImageDialog key={img.imag} props={img} />
	));
	const prototypingTabs = prototyping.map((img) => (
		<ImageDialog key={img.imag} props={img} />
	));

	return (
		<Box
			sx={{
				flexGrow: 1,
				bgcolor: "#09101D",
				display: "flex",
				height: 224,
			}}
			className="mt-10 mobile-design-tabs"
		>
			<Tabs
				orientation="vertical"
				variant="scrollable"
				value={value}
				onChange={handleChange}
				aria-label="Vertical tabs example"
				sx={{ borderRight: 1, borderColor: "#d8261c" }}
			>
				<Tab label="Robotics & AI Lab" {...a11yProps(0)} />

				<Tab label="Open Source Education Robots" {...a11yProps(1)} />
				<Tab label="Product Development" {...a11yProps(2)} />
				<Tab label="Engineering and Product R&D" {...a11yProps(3)} />
				<Tab label="Prototyping and Manufacturing" {...a11yProps(4)} />
			</Tabs>
			<TabPanel value={value} index={0}>
				<div className="grid grid-cols-4 gap-4">{aiLabs}</div>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<div className="grid grid-cols-4 gap-4">{roboticsTabs}</div>
			</TabPanel>
			<TabPanel value={value} index={2} className="">
				<div className="grid grid-cols-4 gap-4">{productsImages}</div>
			</TabPanel>
			<TabPanel value={value} index={3}>
				<div className="grid grid-cols-4 gap-4">{productRandD}</div>
			</TabPanel>
			<TabPanel value={value} index={4}>
				<div className="grid grid-cols-4 gap-4">{prototypingTabs}</div>
			</TabPanel>
		</Box>
	);
}
