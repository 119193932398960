import React from "react";
import { Logo } from "../afribotlogo/Logo";
import { Icon } from "@iconify/react";
export const FooterSection = () => {
	return (
		<div className="flex  justify-between w-full mobile-footer-opt">
			<div className="flex">
				<div className="flex-col justify-between w-1/2">
					<Logo />
					<h1 className="capitalize text-[1.5rem] font-bold pt-[1rem] leading-snug">
						Explore Afribot Robotics Inference Platform
					</h1>

					<div className="icon-fy">
						<a href="https://twitter.com/afribotsystems?lang=en">
							<div className="icon">
								<Icon icon="uiw:twitter" color="#9b9b9b" width="20" />
								<p>Twitter</p>
							</div>
						</a>
						<a href="https://www.linkedin.com/company/afribot-robotics/?viewAsMember=true">
							<div className="icon">
								<Icon icon="line-md:linkedin" color="#9b9b9b" width="20" />
								<p>Linkedin</p>
							</div>
						</a>
						<div className="icon">
							<Icon icon="basil:gmail-outline" color="#9b9b9b" width="20" />
							<p>info@afribot.africa</p>
						</div>
					</div>
				</div>
				<div className="ml-20">
					<h1 className="text-[1.2rem]">Company</h1>
					<a href="#aboutus">
						<p className="text-[14px] mt-4 cursor-pointer">About Us</p>
					</a>
					<a href="#services">
						<p className="text-[14px] mt-4 cursor-pointer ">Services</p>
					</a>
				</div>
			</div>
			<div className="ml-20 map-direction-footer">
				<h1 className="text-[1.2rem]">Location</h1>
				<a href="https://twitter.com/afribotsystems?lang=en">
					<div className="icon">
						<Icon icon="fluent:location-16-filled" />
						<p>Jomo Kenyatta Avenue, Sparky , Kenya</p>
					</div>
				</a>
				<div className="location-map mt-[1rem]">
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d3979.820959200305!2d39.69018811463976!3d-4.0569017229215305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x1840137aa11ac46f%3A0x6ac9b62db894fbf4!2sKingsway%20Tyres%20-%20Jomo%20Kenyatta%20Avenue%2C%20Jomo%20Kenyatta%20Rd%2C%20Mombasa!3m2!1d-4.0569952!2d39.6919286!5e0!3m2!1sen!2ske!4v1684483105609!5m2!1sen!2ske"
						width="600"
						height="200"
						// style="border:0;"
						allowfullscreen=""
						loading="lazy"
						referrerpolicy="no-referrer-when-downgrade"
						className="mobile-map"
					></iframe>
				</div>
			</div>
		</div>
	);
};
