import React, { useState } from "react";
import manufacture from "../../assets/videos/Demo.mp4";
import { Icon } from "@iconify/react";
import { Button } from "../Button";
export const WhoWeAre = () => {
	const [showText, setShowText] = useState(false);

	const handleButtonClick = () => {
		setShowText(!showText);
	};
	return (
		<div className=" items-center justify-between  w-full ">
			<div className="flex items-center justify-between  w-full mobile-design-stem relative">
				<div className="video-container">
					<div className="top-color demo-bottom-overlay"></div>
					<video
						autoPlay
						loop
						muted
						className="video-element w-full who-we-are-video"
					>
						<source src={manufacture} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</div>
				<div className="flex items-center justify-between m-auto full who-we-are">
					<div className="mt-[1rem] w-3/2 ml-20 who-we-mobile">
						<div className="flex items-center title-space-left">
							<div className="h-10 w-[6px] bg-secondary-color mr-[4px]"></div>
							<h1 className=" text-secondary-color font-[600] tracking-wider uppercase">
								Who We Are
							</h1>
						</div>
						<h1 className="capitalize text-[1.6rem] font-bold pt-[1rem] leading-snug">
							We Specialize In Empowering Businesses By Leveraging The Power Of
							Robotics & Artificial Intelligence (AI) To Drive Innovation And
							Growth
						</h1>

						<div className="mt-5 w-3/4 list-elements flex items-center justify-between numbers-who-we-are">
							<div className="years">
								<h1 className="years-number capitalize flex items-center text-[3rem] pt-[1rem] leading-snug text-secondary-color font-[600]">
									<p>100+</p>
									<span className="ml-10 text-[1rem] text-text-color-light">
										Clients
									</span>
								</h1>
								<Icon
									icon="arcticons:uptimerobot"
									color="#9b9b9b7a"
									width="90"
									className="who-we-are-icon"
								/>
							</div>
							<div className="years flex items-center">
								<h1 className="years-number capitalize flex items-center text-[3rem] pt-[1rem] leading-snug text-secondary-color font-[600]">
									<p>140+</p>
									<span className="ml-10 text-[1rem] text-text-color-light">
										Projects
									</span>
								</h1>
								<Icon
									icon="arcticons:uptimerobot"
									color="#9b9b9b7a"
									width="90"
									className="who-we-are-icon"
								/>
							</div>
						</div>
						<div className="relative mt-10" onClick={handleButtonClick}>
							<div className="empty-div-button h-[3rem] w-[3rem] "></div>

							<Button className="unique-btn py-0 flex items-center">
								<Icon
									icon="ant-design:swap-right-outlined"
									color="#d8261c"
									width="30"
								/>{" "}
								<span className="uppercase">Read More</span>
							</Button>
						</div>
					</div>
				</div>
			</div>
			<div
				className={` text-[1.2rem] leading-[1.6rem] mt-10 ${
					showText ? "h-auto" : "h-0 overflow-hidden"
				}`}
			>
				<div className="flex items-center  title-space-left mt-20">
					<div className="h-10 w-[6px] bg-secondary-color mr-[4px] "></div>
					<h1 className=" text-secondary-color font-[600] tracking-wider uppercase">
						About Us
					</h1>
				</div>
				<h1 className="capitalize text-[1.5rem] font-bold pt-[1rem] leading-snug">
					Why work with us?
				</h1>
				<p className="text-text-color-light text-[1.2rem] leading-[1.6rem]">
					Afribot Robotics LTD is a technology-oriented firm that primarily
					focuses on innovative research and product development in the realm of
					robotics. We specialize in the seamless integration of robotics
					systems and the promotion of Science, Technology, Engineering, and
					Mathematics (STEM) education.
				</p>
				<h1 className="capitalize text-[1.5rem] font-bold pt-[1rem] leading-snug">
					Our Story
				</h1>
				<p className="text-text-color-light text-[1.2rem] leading-[1.6rem] ">
					In 2019, an ambitious visionary named Jasin Owili laid the foundation
					for Afribot Robotics, a beacon of innovation and inspiration. His aim
					was simple, yet profoundly impactful - to empower underserved school
					children with Do-It-Yourself, open-source, and affordable electronic
					hardware. These humble beginnings, though steeped in altruism, sparked
					a technological revolution that would ripple across the continent.
				</p>
				<p className="text-text-color-light text-[1.2rem] leading-[1.6rem] mt-10">
					As the demand for electronic products surged across Africa, Afribot
					took a bold step forward. We recognized an opportunity not just to
					meet this growing need, but to empower local entrepreneurs along the
					way. We expanded our services to include product design, aiding
					start-ups and SME's to bring their unique visions to life. With
					Afribot at their side, they could now design and manufacture their
					products with ease and efficiency.
				</p>
				<p className="text-text-color-light text-[1.2rem] leading-[1.6rem] mt-10">
					The strength of Afribot’s mission attracted the attention of other
					established robotics companies. Drawn to our commitment to
					customization and localization, these partnerships allowed us to
					tailor their products and services to resonate with the specific needs
					of the African market.
				</p>
				<p className="text-text-color-light text-[1.2rem] leading-[1.6rem] mt-10">
					In the blink of an eye, Afribot Robotics transitioned from a small,
					lean manufacturing outfit into the region’s premier full-service
					engineering consultancy. It's a journey woven with ingenuity,
					collaboration, and unwavering dedication to empowering our community.
					As we look toward the future, we're excited to continue writing this
					ever-evolving story of technological impact and transformation across
					Africa.
				</p>
			</div>
		</div>
	);
};
