import React from "react";
import addvideo from "../assets/videos/worldgif.gif";
export const VideoComponent = () => {
	return (
		<div className="video-container">
			<div className="top-color flex justify-between items-center">
				<div className="empty-div"></div>
				<div className="img-dog mt-20 ml-20 ">
					<img
						src="https://i.postimg.cc/HnjPKPQk/four-legged-jumping-ro-removebg.png"
						alt="robotics"
						className="w-5/6  object-contain"
					/>
				</div>
			</div>
			<div className="video-element w-full video-mobile-size">
				<img src={addvideo} alt="" className="h-[45rem] object-contain" />
			</div>
		</div>
	);
};
