import React from "react";
import { Button } from "../Button";

export const HeroSection = () => {
	return (
		<div className="mt-[5rem] w-1/2 h-[560px] mobile-position-hero">
			<div className="hero-more">
				<div className="flex items-center">
					<div className="h-10 w-[6px] bg-secondary-color mr-[4px]"></div>
					<h1 className="uppercase text-secondary-color font-[500] tracking-wider">
						Next Generation Platform
					</h1>
				</div>
				<div className="mt-[1rem]">
					<h1 className="capitalize text-[2.8rem] font-bold leading-snug hero-welcome-message">
						Discover Afribot{" "}
						<span className="text-secondary-color ">Robotics </span>&{" "}
						<span className="text-secondary-color ">AI</span> infrence Platform
					</h1>

					<div className="mt-5 w-3/4 text-sizing-mobile">
						<p className="text-[14px] text-explain-mobile">
							Robots takes operations to the next level. They work hand in hand
							with human balancing the imperative for safety with the need for
							flexibility and productivity.
						</p>
					</div>
					<a href="#competition" className="mobile-nav-menu">
						<Button
							className="flex mt-10 uppercase p-10 rounded btn-primary"
							primary
						>
							2023 Robotic Challenge
						</Button>
					</a>
				</div>
			</div>
			<div className="bottom-nav">
				<div className="m-auto w-full p-5 bg-background-color-talent flex items-center justify-between bg-opacity-90 px-[2rem] mobile-adv-div">
					<h1 className="capitalize text-[1.2rem] font-bold leading-snug mr-10">
						Tapping Intelligence through Talent.
					</h1>
					<p className="w-2/3">
						we unlock intelligence through talent. With groundbreaking research,
						innovative products, seamless automation, and inspiring STEM
						education, we push boundaries in AI, robotics, and automation to
						shape the future. Join us to revolutionize industries, empower
						businesses, and inspire young minds for a limitless world of
						innovation.
					</p>
				</div>
			</div>
		</div>
	);
};
