import React from "react";
import { Icon } from "@iconify/react";
export const TeamCard = ({ props }) => {
	return (
		<div className="relative border-b border-secondary-color m-5">
			<img
				src={props.img}
				alt="CEO Jasin Owil"
				className="h-[290px] w-[460px] relative object-cover z-10"
			/>
			<div className="absolute z-20 flex flex-col bottom-0 left-0 w-full h-50px  items-center justify-center bg-opacity-75 backdrop-filter backdrop-blur-md">
				<h2 className=" text-[1.5rem] font-bold justify-center text-secondary-color">
					{props.name}{" "}
				</h2>
				<p className="text-[1rem] m-[5px] italic text-primary-font-color font-[600]">
					{props.profe}
				</p>

				<p className="text-[1rem] m-[5px]">{props.title}</p>
			</div>
			<div className="background-img">
				<Icon icon="solar:star-bold-duotone" color="#d8261c" width="180" />
			</div>
		</div>
	);
};
