import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ImageDialog from "./ImageDialog";
import { products } from "../util/cardlists";
import { randd } from "../util/cardlists";
import { labs } from "../util/cardlists";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`,
	};
}

export default function MobileTabs() {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const productsImages = products.map((img) => (
		<ImageDialog key={img.imag} props={img} />
	));

	const productRandD = randd.map((img) => (
		<ImageDialog key={img.imag} props={img} />
	));
	const aiLabs = labs.map((img) => <ImageDialog key={img.imag} props={img} />);

	return (
		<div className="mobile-tabs-display">
			<Box sx={{ maxWidth: { xs: 420, sm: 380 }, bgcolor: "#09101D" }}>
				<Tabs
					value={value}
					onChange={handleChange}
					variant="scrollable"
					scrollButtons="auto"
					aria-label="scrollable auto tabs example"
				>
					<Tab label="Robotics & AI Lab" {...a11yProps(0)} />
					<Tab label="Product Development" {...a11yProps(1)} />
					<Tab label="Engineering and Product R&D" {...a11yProps(2)} />
					<Tab label="Prototyping and Manufacturing" {...a11yProps(3)} />
				</Tabs>
				<TabPanel value={value} index={0}>
					<div className="grid grid-cols-2 gap-1">{aiLabs}</div>
				</TabPanel>
				<TabPanel value={value} index={1} className="">
					<div className="grid grid-cols-2 gap-1">{productsImages}</div>
				</TabPanel>
				<TabPanel value={value} index={2}>
					<div className="grid grid-cols-2 gap-1">{productRandD}</div>
				</TabPanel>
				<TabPanel value={value} index={3}>
					Prototyping and Manufacturing
				</TabPanel>
			</Box>
		</div>
	);
}
