import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Icon } from "@iconify/react";
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImageDialog({ props }) {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<div className="container cursor-pointer transition duration-300 ease-in-out hover:bg-gray-200 hover:rounded border border-transparent hover:border-secondary-color img-position-grid">
				<img
					src={props.imag}
					alt="Product Img"
					className="image"
					onClick={handleClickOpen}
				/>
			</div>

			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle className="flex justify-between items-center">
					{props.title ? props.title : ""}{" "}
					<Icon
						icon="iconamoon:close-fill"
						width="30"
						onClick={handleClose}
						className="cursor-pointer"
					/>
				</DialogTitle>
				<DialogContent>
					<img src={props.imag} alt="Product Img" />
					<DialogContentText id="alert-dialog-slide-description">
						{props.description}
					</DialogContentText>
				</DialogContent>
				<DialogActions></DialogActions>
			</Dialog>
		</div>
	);
}
