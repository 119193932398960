import React from "react";

// data log
const partners = [
	{
		id: 2,
		logo: "https://i.postimg.cc/HsChCVGX/2-removebg-preview.png",
		link: "https://www.numeraliot.com/",
	},
	{
		id: 12,
		logo: "https://i.postimg.cc/zGQw8cL6/12-removebg-preview.png",
		link: "https://www.unitree.com/",
	},
	{
		id: 11,
		logo: "https://i.postimg.cc/PJ0rVYVL/11-removebg-preview.png",
		link: "https://www.kuka.com/",
	},
	{
		id: 7,
		logo: "https://i.postimg.cc/rpnwvBKR/7-removebg-preview.png",
		link: "https://verity.net/",
	},
	{ id: 1, logo: "https://i.postimg.cc/NfLGXsfy/1-removebg-preview.png" },
	{ id: 3, logo: "https://i.postimg.cc/Y0sWx4w0/3-removebg-preview.png" },
	{ id: 4, logo: "https://i.postimg.cc/RZncXThB/4-removebg-preview.png" },
	{ id: 5, logo: "https://i.postimg.cc/50m58N0Q/5-removebg-preview.png" },
	{ id: 6, logo: "https://i.postimg.cc/8CJndWQJ/6-removebg-preview.png" },
	{ id: 8, logo: "https://i.postimg.cc/pXvb20N0/8-removebg-preview.png" },
	{ id: 9, logo: "https://i.postimg.cc/kGKmTZ0S/9-removebg-preview.png" },
	{ id: 10, logo: "https://i.postimg.cc/W4zcBhRT/10-removebg-preview.png" },

	// Add more partner objects as needed
];

export const Sponsors = () => {
	const PartnerCard = ({ logo }) => {
		return (
			<div className="bg-white p-4 flex justify-center items-center rounded cursor-pointer transition duration-300 ease-in-out hover:bg-gray-200 hover:rounded border border-transparent hover:border-secondary-color">
				<img
					src={logo}
					alt="Partner Logo"
					className="h-20 w-60 object-contain"
				/>
			</div>
		);
	};
	return (
		<div className="flex items-center justify-between   w-full">
			<div className="flex items-center justify-between ">
				<div className="mt-[1rem]">
					<div className="flex items-center ">
						<div className="h-10 w-[6px] bg-secondary-color mr-[4px]"></div>
						<h1 className=" text-secondary-color font-[600] tracking-wider uppercase">
							Successful
						</h1>
					</div>
					<h1 className="capitalize text-[1.5rem] font-bold pt-[1rem] leading-snug">
						Our Partners
					</h1>

					<div className="mt-5  list-elements">
						<div className="mobile-partners-cards grid grid-cols-6 gap-4">
							{partners.map((partner) => (
								<a href={partner.link} key={partner.id}>
									<PartnerCard logo={partner.logo} />
								</a>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
