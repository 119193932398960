import React from "react";
import { TeamCard } from "../TeamCard";

export const TheTeam = () => {
	const imagesList = [
		{
			title: "CEO Afribot",
			name: "Jason Owili",
			profe: "Embedded Systems & ROS",
			img: "https://i.postimg.cc/0QYSqQ7W/Untitled-design-removebg-preview.png",
			linkedin: "https://www.linkedin.com/in/jasin-owili-378910149/",
		},
		{
			title: "Head of Innovations",
			name: "Amal Abdallah",
			profe: "Data Scientist",
			img: "https://i.postimg.cc/V6Pv6v5M/IMG-9241-removebg-preview.png",
			linkedin: "https://www.linkedin.com/in/amal-abdallah-0b1a56150/",
		},
		{
			title: "Mechatronics Engineer",
			profe: "Tecknical Lead",
			name: "Gavins Maragia",
			img: "https://i.postimg.cc/d1G7mdg4/Gavins-Maragia-3-removebg-preview.png",
			linkedin: "https://www.linkedin.com/in/gavinsmaragia/",
		},
	];
	const teamLeadAfriBot = imagesList.map((img) => (
		<a href={img.linkedin} key={img.title} className="team-box-card">
			<TeamCard props={img} />
		</a>
	));
	return (
		<div className="flex items-center justify-between   w-full mobile-design-stem">
			<div className="flex items-center justify-between m-auto full">
				<div className="mt-[1rem] w-6/5">
					<div className="flex items-center ">
						<div className="h-10 w-[6px] bg-secondary-color mr-[4px]"></div>
						<h1 className=" text-secondary-color font-[600] tracking-wider uppercase">
							Meet The Team
						</h1>
					</div>
					<h1 className="capitalize text-[1.5rem] font-bold pt-[1rem] leading-snug">
						We exemplify the embodiment of intelligence through our dynamic
						implementation.
					</h1>

					<div className="mt-5 w-3/4 list-elements">
						<ul>
							<li>
								<p>
									Our leadership team possesses extensive expertise in Robotics,
									Artificial Intelligence (AI) and Technology innovation,
									accumulated over many years.
								</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="flex grid-cols-2 gap-1 card-list-team mt-10">
				{teamLeadAfriBot}{" "}
			</div>
		</div>
	);
};
