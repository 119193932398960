import React from "react";
import { Icon } from "@iconify/react";
export const CardServices = ({ props, i }) => {
	const listElements = props.list.map((list) => (
		<p className="mt-2 text-text-color-light" key={i}>
			{list}
		</p>
	));
	return (
		<div className="cursor-pointer rounded border-2 p-5 border-secondary-color hover-box-shadow transition duration-300 ease-in-out">
			<div className="border-2  border-text-color-page rounded justify-center">
				<Icon icon={props.icon} color="white" width="45" className="m-5" />
			</div>
			<h1 className="bold text-[1.5rem] my-5">{props.title}</h1>
			<hr className="my-5" />
			{listElements}
		</div>
	);
};
