import React, { useState, useRef } from "react";
import manufacture from "../../assets/videos/WRC 2023.mp4";
import { Icon } from "@iconify/react";
import { Button } from "../Button";
import { Typewriter } from "react-simple-typewriter";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import YouTube from "react-youtube";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});
export const Contest = () => {
	const [open, setOpen] = React.useState(false);
	const playerRef = useRef(null);
	const [isVideoPlaying, setIsVideoPlaying] = React.useState(true);
	const [videoId, setVideoId] = useState("xURjES1DheE");

	const handleClickOpen = () => {
		if (playerRef.current) {
			playerRef.current.internalPlayer.pauseVideo();
		}
		setOpen(true);
	};
	// Pause the video on close button
	const videoRef = React.useRef(null);
	const handleClose = () => {
		setOpen(false);
	};
	return (
		<div className=" items-center justify-between  w-full mobile-design-stem">
			<div className="title text-center w-1/2 m-auto title-services">
				<h1
					id="services"
					className=" text-secondary-color font-[600] text-[1.5rem]  tracking-wider uppercase"
				>
					STAR ALLIANCE ROBOTICS CHALLENGE
				</h1>
				<h1 className="capitalize text-[1.5rem] font-bold pt-[1rem] leading-snug">
					Pushing Boundaries, Inspiring Discoveries
				</h1>
			</div>
			<div className="flex items-center justify-between mt-10  w-full mobile-design-stem relative">
				<div className="flex items-center justify-between m-auto full who-we-are mobile-design-stem">
					<div className="mt-[1rem] w-3/2 who-we-mobile">
						<div className="flex items-center title-space-left">
							<div className="h-10 w-[6px] bg-secondary-color mr-[4px]"></div>
							<h1 className=" text-secondary-color font-[600] tracking-wider uppercase">
								Super Track Competition 2023
							</h1>
						</div>
						<h1 className="capitalize w-4/5 text-[1rem] font-bold pt-[1rem] leading-snug">
							Join us for an extraordinary robotics competition as we present
							the{" "}
							<span className="text-secondary-color">
								"Super Track Competition 2023: Star Alliance Robotics
								Challenge."
							</span>{" "}
							This thrilling event invites talented individuals and teams to
							showcase their ingenuity and engineering prowess in designing
							autonomous robots capable of navigating a designated path.
						</h1>
						<h1 className="capitalize text-[1.5rem]  font-bold pt-[1rem] leading-snug">
							The Competition is divided into
							<span className="capitalize text-[1.5rem] ml-2 text-secondary-color font-bold pt-[1rem] leading-snug">
								<Typewriter
									words={[
										" { Elementary School }",
										" { Middle School }",
										" { High School }",
									]}
									loop={100}
									cursor
									cursorStyle="_"
									cursorSize="30px"
									typeSpeed={70}
									deleteSpeed={50}
									delaySpeed={1000}
									className="auto-type ml-5 w-90 text-secondary-color"
								/>
							</span>
						</h1>
						<div className="mt-5">
							<span className="text-[1rem] mt-10 text-text-color-light">
								Theme
							</span>
							<p className="text-[1.5rem] w-90 text-secondary-color font-[600]">
								Star Alliance & Team Formation
							</p>
						</div>

						<div className="relative mt-10" onClick={handleClickOpen}>
							<div className="empty-div-button h-[3rem] w-[3rem] "></div>

							<Button
								className="unique-btn py-0 flex items-center"
								onClick={handleClickOpen}
							>
								<Icon
									icon="ant-design:swap-right-outlined"
									color="#d8261c"
									width="30"
								/>{" "}
								<span className="uppercase ">Watch Video Description</span>
							</Button>
						</div>
					</div>
					<div className="video-container">
						<div className="top-color demo-bottom-overlay object-cover competition-color-over-lay"></div>
						<div
							ref={videoRef}
							loop
							controls
							style={{
								height: "400px",
								objectFit: "container",
							}}
							className="video-element w-full who-we-are-video mobile-compe"
						>
							<img
								src="https://i.postimg.cc/dtFGTnFp/Screenshot-from-2023-06-06-20-03-47.png"
								alt="competition road map"
								style={{
									width: "100%",
									height: "inherit",
									objectFit: "contain",
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle className="flex justify-between items-center">
					{"World Robot Contest, WRC2023"}{" "}
					<Icon
						icon="iconamoon:close-fill"
						width="30"
						onClick={handleClose}
						className="cursor-pointer"
					/>
				</DialogTitle>
				<DialogContent>
					<YouTube
						videoId={videoId}
						opts={{
							height: "360",
							width: "560",
							playerVars: {},
						}}
						ref={playerRef}
					/>
					<DialogContentText id="alert-dialog-slide-description">
						The 2023 Super Track Competition is a futuristic,
						interstellar-themed event set in the year 2120 of the New Era. The
						backstory involves two exploration teams from the Human Helios
						Project who, after a challenging journey, meet on the planet Y1799.
						They decide to build an interstellar base for mankind on this unique
						planet, constructing necessary production and living facilities.
					</DialogContentText>
					<DialogContentText id="alert-dialog-slide-description">
						As their new home takes shape, they form an interstellar alliance to
						protect it and prepare for further space exploration. This alliance
						symbolizes the joint effort to improve living standards and embark
						on new exploratory journeys.
					</DialogContentText>
					<DialogContentText id="alert-dialog-slide-description">
						The competition invites young students to participate by using
						self-made robots to write programs, debug, and complete competition
						tasks. The theme of the competition, "Star Alliance," aims to mimic
						the process of interstellar exploration and alliance building
						through these tasks.
					</DialogContentText>
					<DialogContentText id="alert-dialog-slide-description">
						Beyond fostering a love for scientific knowledge, the competition is
						designed to enhance participants' thinking abilities, response
						capabilities, hands-on coordination skills, and team spirit. The
						competition is divided into three groups: elementary school, middle
						school, and high school, and is completed in teams. Each team
						consists of 1 to 2 contestants and 1 to 2 tutors, and the
						contestants must be current students until June 2023.
					</DialogContentText>
				</DialogContent>
				<DialogActions></DialogActions>
			</Dialog>
		</div>
	);
};
