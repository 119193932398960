export const data = [
	{
		icon: "carbon:workflow-automation",
		title: "Product Development",
		list: [
			"Hardware Development",
			"Idea & Concept Development",
			"Industrial Design",
			"Enclosure Design",
			"Design Thinking Service & Product Strategy",
			"Feasibility Studies",
			"Digital Transformation",
		],
	},
	{
		icon: "grommet-icons:cpu",
		title: "Engineering and Product R&D",
		list: [
			"Mechanical Engineering",
			"Custom Electronics Development",
			"Firmware Development",
			"Reverse Engineering",
			"Design for Manufacturing (DFM)",
			"Product R&D",
			"Computer Simulations",
		],
	},
	{
		icon: "mdi:robot-industrial-outline",
		title: "Prototyping and Manufacturing",
		list: [
			"Rapid Prototyping",
			"Proof-of-Concept Prototyping",
			"Low Volume Manufacturing",
			"Manufacturing Support & Sourcing",
			"Jigs and Fixture Design Services",
			"Moulds and Tools Manufacturing",
			"",
		],
	},
];

export const products = [
	{
		imag: "https://i.postimg.cc/RCYz7t0c/Whats-App-Image-2023-05-17-at-6-07-32-AM-2-removebg-preview.png",
	},
	{
		imag: "https://i.postimg.cc/T1zVnkmh/Whats-App-Image-2023-05-17-at-6-07-39-AM-removebg-preview.png",
	},
	{
		imag: "https://i.postimg.cc/sX17qF4v/Whats-App-Image-2023-05-17-at-6-07-38-AM-removebg-preview.png",
	},
	{
		imag: "https://i.postimg.cc/vZdLkPBb/Whats-App-Image-2023-05-17-at-6-07-38-AM-1-removebg-preview.png",
	},
	{
		imag: "https://i.postimg.cc/zXTTCnkM/Whats-App-Image-2023-05-17-at-6-07-37-AM-removebg-preview-copy.png",
	},
	{
		imag: "https://i.postimg.cc/FHDdpGkP/Whats-App-Image-2023-05-17-at-6-07-37-AM-1-removebg-preview.png",
	},
	{
		imag: "https://i.postimg.cc/Y261g6RP/Whats-App-Image-2023-05-17-at-6-07-36-AM-removebg-preview-copy.png",
	},
	{
		imag: "https://i.postimg.cc/zfCcvgsn/Whats-App-Image-2023-05-17-at-6-07-40-AM-removebg-preview.png",
	},
	{
		imag: "https://i.postimg.cc/wx21g009/Whats-App-Image-2023-05-17-at-6-07-34-AM-removebg-preview.png",
	},
	{
		imag: "https://i.postimg.cc/mDRrGH9c/Whats-App-Image-2023-05-17-at-6-07-34-AM-1-removebg-preview.png",
	},
	{
		imag: "https://i.postimg.cc/c4j4LjYG/Whats-App-Image-2023-05-17-at-6-07-33-AM-2-removebg-preview.png",
	},
	{
		imag: "https://i.postimg.cc/L8LR6s2j/Whats-App-Image-2023-05-17-at-6-07-32-AM-removebg-preview.png",
	},
];
export const randd = [
	{
		imag: "https://i.postimg.cc/sxxmw1w8/alexandre-debieve-FO7-JIlwj-Ot-U-unsplash.jpg",
	},
	{
		title: "Ultrasonic flow meter",
		imag: "https://i.postimg.cc/7P5Stvqp/Smart-Water-Meter.jpg",
		description:
			"Our Ultrasonic water meter is designed for accurate measurement of both hot and cold water consumption in houses, office premises, and apartment buildings.",
	},
	{
		title: "Ultrasonic flow meter",
		imag: "https://i.postimg.cc/Gt62VSxR/Smart-Water-Meter-V2.jpg",
		description:
			"Our Ultrasonic water meter is designed for accurate measurement of both hot and cold water consumption in houses, office premises, and apartment buildings.",
	},
	{
		title: "Smart Shopping Cart",
		imag: "https://i.postimg.cc/FsjdwN8n/Smart-Shopping-Cart.jpg",
		description:
			"The smart shopping cart is equipped with a large touchscreen at the front, when customers scan an item's bar code, the smart shopping cart displays the item's price automatically. When customers scan another item, the smart shopping cart displays another item's price accordingly.",
	},
	{
		imag: "https://i.postimg.cc/90kRVfVv/testalize-me-9x-Hs-Wmh3m-4-unsplash-1.jpg",
	},
	{
		imag: "https://i.postimg.cc/Qt1hVxp2/minku-kang-a-Cni-NTi-IFd8-unsplash.jpg",
	},
	{
		imag: "https://i.postimg.cc/X7766gzS/maximalfocus-MHv-Vn-KKloxk-unsplash.jpg",
	},
	{
		title: "Autonomous Guided Robot (AVG)",
		imag: "https://i.postimg.cc/mkLJRbVN/Automated-Guided-Vehicle-AGV.jpg",
		description:
			"Afribot Robotics is designing an exceptional Autonomous Guided Robot (AGV) that operates autonomously, performing tasks and efficiently transporting goods or materials without the need for human intervention. Employing advanced sensors, intelligent algorithms, and predefined routes, Afribot's AGV revolutionizes industries such as logistics, manufacturing, and warehousing with its unparalleled efficiency and productivity.",
	},
	{
		imag: "https://i.postimg.cc/NjzNV6W4/trnava-university-9x-RHr-MOjeg-unsplash.jpg",
	},
	{
		imag: "https://i.postimg.cc/JzFsk0k0/Whats-App-Image-2023-05-19-at-12-56-23-PM-1.jpg",
	},
	{
		imag: "https://i.postimg.cc/HssnvX0j/Whats-App-Image-2023-05-19-at-12-56-23-PM.jpg",
	},
	{
		imag: "https://i.postimg.cc/MHZ64JQg/Whats-App-Image-2023-05-19-at-8-10-34-PM.jpg",
	},
];
export const labs = [
	{
		imag: "https://i.postimg.cc/SNwXrZMC/Old-Redux-VSRedux-Toolkit.png",
	},
	{
		imag: "https://i.postimg.cc/N0jgkQQ2/12.jpg",
	},
	{
		imag: "https://i.postimg.cc/NMLTpr5y/330-4-2.png",
	},
	{
		imag: "https://i.postimg.cc/L8HRmmYQ/16.jpg",
	},
	{
		imag: "https://i.postimg.cc/C1NLp75P/3-Advanced72.png",
	},
	{
		imag: "https://i.postimg.cc/Hx8y9fLG/Screenshot2.png",
	},
	{
		imag: "https://i.postimg.cc/yxCfzhJG/Model-4.png",
	},
	{
		imag: "https://i.postimg.cc/Gt2hjDK4/Screenshot3.png",
	},
	{
		imag: "https://i.postimg.cc/nLJd9GVG/Model-F1.png",
	},
	{
		imag: "https://i.postimg.cc/Dw8hYF1M/Model-2.png",
	},
	{
		imag: "https://i.postimg.cc/HnDjTn9n/330-7-3.png",
	},
	{
		imag: "https://i.postimg.cc/J0GqD2b2/Model-1.png",
	},
];
export const robotics = [
	{
		imag: "https://i.postimg.cc/sD2Yy9gk/Whats-App-Image-2023-05-17-at-6-30-40-AM.jpg",
	},
	{
		imag: "https://i.postimg.cc/9Q6ZG6Y2/Whats-App-Image-2023-05-17-at-6-30-37-AM.jpg",
	},
	{
		imag: "https://i.postimg.cc/503zW6d9/Whats-App-Image-2023-05-17-at-6-30-36-AM.jpg",
	},
	{
		imag: "https://i.postimg.cc/WbtkxN9j/Whats-App-Image-2023-05-17-at-6-30-35-AM.jpg",
	},

	{
		imag: "https://i.postimg.cc/jj9xwTH6/Whats-App-Image-2023-05-17-at-6-30-33-AM.jpg",
	},
	{
		imag: "https://i.postimg.cc/k4Km2bX9/Whats-App-Image-2023-05-17-at-6-30-18-AM.jpg",
	},
];

export const prototyping = [
	{
		imag: "https://i.postimg.cc/yNKXqqgP/Whats-App-Image-2023-05-17-at-6-07-40-AM-1.jpg",
	},
	{
		imag: "https://i.postimg.cc/1X8D0s29/Whats-App-Image-2023-05-17-at-6-07-39-AM-5.jpg",
	},
	{
		imag: "https://i.postimg.cc/NLh21TR4/Whats-App-Image-2023-05-17-at-6-07-39-AM-4.jpg",
	},
	{
		imag: "https://i.postimg.cc/qvxQvMdY/Whats-App-Image-2023-05-17-at-6-07-39-AM.jpg",
	},
	{
		imag: "https://i.postimg.cc/RFNTd7n5/Whats-App-Image-2023-05-17-at-6-07-37-AM.jpg",
	},
	{
		imag: "https://i.postimg.cc/GmdJVwsj/Whats-App-Image-2023-05-17-at-6-07-35-AM-5.jpg",
	},
	{
		imag: "https://i.postimg.cc/br1kV3yM/Whats-App-Image-2023-05-17-at-6-07-32-AM-1.jpg",
	},
];
