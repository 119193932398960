import React from "react";
import ImageLogo from "../../assets/images/Screenshot_from_2023-05-17_02-28-25-removebg-preview.png";
export const Logo = () => {
	return (
		<div className="">
			<img
				src={ImageLogo}
				alt="Jumia Logo"
				className="object-cover h-10 w-[15rem] icon-logo-mobile"
			/>
		</div>
	);
};
