import React from "react";
import { Icon } from "@iconify/react";
import { CardServices } from "../CardServices";
import { data } from "../../util/cardlists";
export const OurServices = () => {
	const cardsElement = data.map((card) => (
		<div className="mt-5" key={card.title}>
			<CardServices props={card} />
		</div>
	));
	return (
		<div w-full>
			<div className="title text-center w-1/2 m-auto title-services">
				<h1
					id="services"
					className=" text-secondary-color font-[600] text-[1.5rem]  tracking-wider uppercase"
				>
					Our Services
				</h1>
				<h1 className="capitalize text-[1.5rem] font-bold pt-[1rem] leading-snug">
					Our purpose is to consistently deliver outstanding service and
					exceptional execution.
				</h1>
			</div>
			<div className="cards mt-20 flex justify-between p-5 text-left   mobile-design-stem">
				{cardsElement}
			</div>
		</div>
	);
};
