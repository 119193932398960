import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
export const SliderTetimonial = () => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const slides = [
		{
			title: "Jared Ateng ",
			position: "Instrumentation and Control Engineer/TECPAK AGENCIES LIMITED",
			description:
				"Afribot Robotics has completely transformed our approach to automation. Their cutting-edge technology and exceptional expertise have revolutionized our operations, making us more efficient and competitive in the market.",
			imageSrc:
				"https://i.postimg.cc/tTLgSgTX/310528911-1484039155354986-4936320391105785963-n.jpg",
		},
		{
			title: "Jonathan Otieno",
			position: "Founder & CEO/Bike is Best",
			description:
				"Afribot Robotics LTD has demonstrated an impressive level of innovation and professionalism throughout our collaboration. From the initial idea stage to Engineering and Product Design, they have been instrumental in developing our IoT Tracking, Bike Parking, and Charging Stations solution",
			imageSrc:
				"https://i.postimg.cc/05znz5Jc/Whats-App-Image-2023-05-26-at-12-28-25-PM.jpg",
		},
	];
	useEffect(() => {
		const timer = setInterval(() => {
			goToNextSlide();
		}, 10000); // Automatic slide transition every 5 seconds

		return () => {
			clearInterval(timer);
		};
	}, [currentSlide]);

	const goToNextSlide = () => {
		setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
	};

	const goToPreviousSlide = () => {
		setCurrentSlide(
			(prevSlide) => (prevSlide - 1 + slides.length) % slides.length
		);
	};

	const currentSlideData = slides[currentSlide];

	return (
		<div className="relative w-full flex items-center justify-center slider-img">
			<div className="max-w-2xl w-full p-4 items-center justify-center  relative text-center">
				<h1 className=" text-secondary-color font-[600] tracking-wider uppercase py-5 text-[1.2rem]">
					Testimonial
				</h1>
				<h1 className="capitalize text-[1.5rem] font-bold py-[.5rem] leading-snug">
					Our Clients and Students Testimonials: What They Say About Us
				</h1>
				<img
					src={currentSlideData.imageSrc}
					alt="Slide "
					className="rounded-lg img-slider"
				/>
				<div className="relative pt-[11rem]">
					<div className=" w-full inset-0 bg-black bg-opacity-50 rounded-lg"></div>
					<div className=" inset-0 flex flex-col items-center justify-center text-white text-center px-4">
						<p className="text-lg mb-4 flex text-text-color-light">
							<span>
								<Icon icon="ri:double-quotes-l" width="50" color="#D8261C" />
							</span>
							{currentSlideData.description}
							<span>
								<Icon icon="ri:double-quotes-r" width="50" color="#D8261C" />
							</span>
						</p>
						<h3 className="text-2xl font-bold mb-2">
							{currentSlideData.title}
						</h3>
						<h3 className="italic text-[1rem] text-secondary-color font-[600]">
							{currentSlideData.position}
						</h3>
					</div>
				</div>
			</div>
			<div className="flex w-full space-x-4 next-prev-btn">
				<button
					onClick={goToPreviousSlide}
					className="px-4 py-2 rounded-lg  text-white"
				>
					<Icon icon="carbon:previous-outline" color="white" width="40" />
				</button>
				<button
					onClick={goToNextSlide}
					className="px-4 py-2 rounded-lg  text-white"
				>
					<Icon icon="carbon:next-outline" color="white" width="40" />
				</button>
			</div>
		</div>
	);
};
