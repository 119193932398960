import React, { useEffect, useState } from "react";
import { StemEducation } from "./components/afibotservices/StemEducation";
import { HeroSection } from "./components/homePage/HeroSection";
import { MaxScreenSize } from "./components/MaxScreenSize";
import { NavBar } from "./components/navbar/NavBar";
import { VideoComponent } from "./components/VideoAdd";
import stemImage from "./assets/images/WhatsApp Image 2023-05-17 at 6.30.22 AM.jpeg";
import { ProductManagementRD } from "./components/afibotservices/ProductManagementRD";
import { MobileRoboteIIntegration } from "./components/afibotservices/MobileRoboteIIntegration";
import { OurServices } from "./components/afibotservices/OurServices";
import { WhoWeAre } from "./components/afibotservices/WhoWeAre";
import { TheTeam } from "./components/afibotservices/TheTeam";
import { Sponsors } from "./components/afibotservices/Sponsors";
import { FooterSection } from "./components/afibotservices/FooterSection";
import ServicesTabs from "./components/ServicesTabs";
import MobileTabs from "./components/MobileTabs";
import { Contest } from "./components/afibotservices/Contest";
import { SliderTetimonial } from "./components/SliderTetimonial";

function App() {
	const [shouldSlideIn, setShouldSlideIn] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const slideInElements = document.querySelectorAll(".slide-in");
			slideInElements.forEach((element) => {
				const elementTop = element.getBoundingClientRect().top;
				const slideInAt = window.innerHeight * 0.8; // Adjust this value as needed

				if (elementTop < slideInAt) {
					element.classList.add("slide-in-active");
				} else {
					element.classList.remove("slide-in-active");
				}
			});
		};

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<div className="App bg-page-bg-color text-text-color-page h-[100%]">
			<div className="relative">
				<div
					id="home"
					className="hero-secion relative w-full m-auto max-w-7xl items-center justify-between"
				>
					{/* the navigation Bar */}
					<VideoComponent />
					<div className="absolute top-0 w-full">
						<MaxScreenSize className="navbar w-full  m-auto flex-col justify-center ">
							<NavBar />
							{/* Hero Section */}
							<HeroSection />
							{/* stem Education */}
						</MaxScreenSize>
					</div>
				</div>
				<div id="getstarted" className=" relative">
					<div className="relative">
						<div className="empty-img-cover w-full h-80"></div>
						<img
							className="w-full object-cover h-80"
							src={stemImage}
							alt="Stem Education"
						/>
					</div>
					<div className="top-content w-full">
						<div
							className={`slide-in ${shouldSlideIn ? "slide-in-active" : ""}`}
						>
							<MaxScreenSize>
								<StemEducation />
							</MaxScreenSize>
						</div>
					</div>
					{/* R&D */}
					<div className={`slide-in ${shouldSlideIn ? "slide-in-active" : ""}`}>
						<div className=" w-full">
							<MaxScreenSize>
								<ProductManagementRD />
							</MaxScreenSize>
						</div>
					</div>
					{/* MobileRoboteIIntegration */}
					<div className={`slide-in ${shouldSlideIn ? "slide-in-active" : ""}`}>
						<div className="w-full">
							<MaxScreenSize>
								<MobileRoboteIIntegration />
							</MaxScreenSize>
						</div>
					</div>
					<div className="w-full mt-20">
						<MaxScreenSize>
							<OurServices />

							<div
								id="robotics"
								className={`slide-in ${shouldSlideIn ? "slide-in-active" : ""}`}
							>
								<ServicesTabs />
							</div>
							<div
								className={`slide-in ${shouldSlideIn ? "slide-in-active" : ""}`}
							>
								<MobileTabs />
							</div>
						</MaxScreenSize>
					</div>
					<div id="aboutus" className="w-full mt-10 ">
						<MaxScreenSize>
							<WhoWeAre />
						</MaxScreenSize>
					</div>
					<div className={`slide-in ${shouldSlideIn ? "slide-in-active" : ""}`}>
						<div className="w-full mt-10">
							<MaxScreenSize>
								<TheTeam />
							</MaxScreenSize>
						</div>
					</div>
					{/* Advert for the upcoming contest
					 */}
					<div id="competition" className="w-full mt-20 ">
						<MaxScreenSize>
							<Contest />
						</MaxScreenSize>
					</div>
					{/* Slider section */}
					<div
						className={`slide-in ${
							shouldSlideIn ? "slide-in-active" : ""
						} mt-mobile-scren`}
					>
						<div className="w-full mt-10">
							<MaxScreenSize>
								<SliderTetimonial />
							</MaxScreenSize>
						</div>
					</div>
					<div
						className={`slide-in ${shouldSlideIn ? "slide-in-active" : ""} `}
					>
						<div className="w-full mt-10">
							<MaxScreenSize>
								<Sponsors />
							</MaxScreenSize>
						</div>
					</div>
					<div className="w-full mt-10">
						<hr className="bg-primary-hover-effect my-10" />
						<div
							className={`slide-in ${shouldSlideIn ? "slide-in-active" : ""}`}
						>
							<MaxScreenSize>
								<FooterSection />
							</MaxScreenSize>
						</div>
						<div className="compy py-3 mt-4 bg-footer-bg-color">
							<MaxScreenSize className="flex">
								<p>© 2023 Afribot. All rights reserved</p>
								<div className="flex justify-between">
									<p>Privacy</p>
									<p>Disclaimer</p>
									<p>Terms and Conditions</p>
								</div>
							</MaxScreenSize>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
