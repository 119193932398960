import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { Icon } from "@iconify/react";

export default function SideBarMenu() {
	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event &&
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};

	const list = (anchor) => (
		<Box
			sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<List>
				{[
					{ menu: "Home", link: "#home" },
					{ menu: "Services", link: "#services" },
					{ menu: "Robotics", link: "#robotics" },
					{ menu: "Projects", link: "#projects" },
					{ menu: "About Us", link: "#aboutus" },
				].map((text, index) => (
					<a href={text.link} key={text.menu}>
						<ListItem disablePadding>
							<ListItemButton>
								<ListItemText primary={text.menu} />
							</ListItemButton>
						</ListItem>
					</a>
				))}
			</List>
			<Divider />
			<a href="#getstarted" className="mobile-nav-menu">
				<Button
					className="flex mx-5 uppercase p-10 rounded btn-primary"
					primary
				>
					Get Started
				</Button>
			</a>
		</Box>
	);

	return (
		<div className="mobile-menu-display">
			{["left"].map((anchor) => (
				<React.Fragment key={anchor}>
					<Button onClick={toggleDrawer(anchor, true)}>
						{" "}
						<Icon icon="mingcute:menu-fill" color="white" width="30" />
					</Button>
					<SwipeableDrawer
						anchor={anchor}
						open={state[anchor]}
						onClose={toggleDrawer(anchor, false)}
						onOpen={toggleDrawer(anchor, true)}
					>
						{list(anchor)}
					</SwipeableDrawer>
				</React.Fragment>
			))}
		</div>
	);
}
