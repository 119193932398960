import React, { useState, useEffect } from "react";
import { Logo } from "../afribotlogo/Logo";
import { Button } from "../Button";
import SideBarMenu from "../SideBarMenu";
export const NavBar = () => {
	const [scrolled, setScrolled] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const scrollTop = window.pageYOffset;
			if (scrollTop > 0) {
				setScrolled(true);
			} else {
				setScrolled(false);
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const navBarClassName = scrolled
		? "fixed top-0 left-0 right-0 bg-page-bg-color"
		: "";

	return (
		<div
			className={`w-full flex items-center py-5 z-20 justify-between m-auto max-w-7xl prime-nav ${navBarClassName}`}
		>
			<Logo />
			<div className="flex items-center justify-between nav-bar-links mobile-nav-menu">
				<ul className="w-full flex items-center capitalize">
					<li>
						<a href="#home">Home</a>
					</li>
					<li>
						<a href="#services">services</a>
					</li>
					<li>
						<a href="#robotics">Robotics</a>
					</li>
					<li>
						<a href="#robotics">projects</a>
					</li>
					<li>
						<a href="#aboutus">About Us</a>
					</li>
				</ul>
			</div>
			<a href="#getstarted" className="mobile-nav-menu">
				<Button
					className="flex mx-5 uppercase p-10 rounded btn-primary"
					primary
				>
					Get Started
				</Button>
			</a>
			<SideBarMenu />
		</div>
	);
};
